const dev = {
	s3: {
		REGION: "ap-southeast-1",
		BUCKET: "scribble-api-dev-attachmentsbucket-1xv44l74ag1rb"
	},
	apiGateway: {
		REGION: "ap-southeast-1",
		URL: "https://fhrj09we0c.execute-api.ap-southeast-1.amazonaws.com/dev"
	},
	cognito: {
		REGION: "ap-southeast-1",
		USER_POOL_ID: "ap-southeast-1_1iYxCz5rX",
		APP_CLIENT_ID: "71026bvqobn8g8t4de43l0trj0",
		IDENTITY_POOL_ID: "ap-southeast-1:a97076a8-7d3b-404c-9402-415dc9d15853"
	},
	social: {
		FB: "366892593937583"
	}
};

const prod = {
	s3: {
		REGION: "ap-southeast-1",
		BUCKET: "scribble-api-prod-attachmentsbucket-masv03meqi2n"
	},
	apiGateway: {
		REGION: "ap-southeast-1",
		URL: "https://1kpezt33b5.execute-api.ap-southeast-1.amazonaws.com/prod"
	},
	cognito: {
		REGION: "ap-southeast-1",
		USER_POOL_ID: "ap-southeast-1_eodvCeNbt",
		APP_CLIENT_ID: "6epj0s6jh4b6u8ohltu8bun80",
		IDENTITY_POOL_ID: "ap-southeast-1:8d771c5c-01b6-436d-aa02-86d031ff5da1"
	},
	social: {
		FB: "2253280351604410"
	}
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
	? prod
	: dev;

export default {
	// Add common config values here
	MAX_ATTACHMENT_SIZE: 5000000,
	...config
};
