import React, { Component } from "react";
import {ListGroupItem} from "react-bootstrap";
import moment from "moment";
import {LinkContainer} from "react-router-bootstrap";
import "./NoteListItem.css";
import {Storage} from "aws-amplify";




export default class NoteListItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			header: this.props.note.content.trim().split("\n")[0],
			dateText: `Created: ${moment(this.props.note.createdAt).format("DD MMM YYYY h:mmA")}`,
			imageURL: null,
			linkKey: this.props.note.noteId,
			link: `/notes/${this.props.note.noteId}`}
		;
	}

	componentDidMount() {
		const {attachment} = this.props.note
		if (attachment) {
			Storage.vault.get(attachment)
				.then(imageURL => this.setState({imageURL}))
		}
	}

	render() {
		return (<LinkContainer
			key={this.state.linkKey}
			to={this.state.link}>
			<ListGroupItem>
				{this.state.imageURL && <img src={this.state.imageURL} className="img-thumbnail note-image"/>}
				<h4>{this.state.header}</h4>
				{this.state.dateText}
				<br className="clearfix" />
			</ListGroupItem>
		</LinkContainer>)
	}
}
