import React, { Component } from "react";
import {
	HelpBlock,
	FormGroup,
	FormControl,
	ControlLabel
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Signup.css";
import {Auth, JS} from "aws-amplify";
import FacebookButton from "../components/FacebookButton";


export default class Signup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			email: "",
			password: "",
			confirmPassword: "",
			confirmationCode: "",
			newUser: null
		};
	}

	validateForm() {
		return (
			this.state.email.length > 0 &&
			this.state.password.length > 0 &&
			this.state.password === this.state.confirmPassword
		);
	}

	validateConfirmationForm() {
		return this.state.confirmationCode.length > 0;
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	}

	handleSubmit = async event => {
		event.preventDefault();

		this.setState({ isLoading: true });

		try {
			const newUser = await Auth.signUp({
				username: this.state.email,
				password: this.state.password
			});
			this.setState({
				newUser
			});
		} catch (e) {
			if (e && e.code === 'UsernameExistsException') {
				this.handleResendSignUp()
				return
			}

			alert(e.message);
		}

		this.setState({ isLoading: false });
	}

	handleResendSignUp = () => {
		this.setState({ isLoading: true });
		Auth.resendSignUp(this.state.email)
			.then(newUser => this.setState({newUser}))
			.catch(error => {
				alert(error.message);
				this.setState({isLoading: false})
			})
	}

	handleFbLogin = () => {
		this.props.userHasAuthenticated(true, true);
	};

	handleConfirmationSubmit = async event => {
		event.preventDefault();

		this.setState({ isLoading: true });

		try {
			await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
			await Auth.signIn(this.state.email, this.state.password);

			this.props.userHasAuthenticated(true);
			this.props.history.push("/");
		} catch (e) {
			alert(e.message);
			this.setState({ isLoading: false });
		}
	}


	renderConfirmationForm() {
		return (
			<form onSubmit={this.handleConfirmationSubmit}>
				<FormGroup controlId="confirmationCode" bsSize="large">
					<ControlLabel>Confirmation Code</ControlLabel>
					<FormControl
						autoFocus
						type="tel"
						value={this.state.confirmationCode}
						onChange={this.handleChange}
					/>
					<HelpBlock>Please check your email for the code.</HelpBlock>
				</FormGroup>
				<LoaderButton
					block
					bsSize="large"
					disabled={!this.validateConfirmationForm()}
					type="submit"
					isLoading={this.state.isLoading}
					text="Verify"
					loadingText="Verifying…"
				/>
			</form>
		);
	}

	renderForm() {
		return (
			<form onSubmit={this.handleSubmit}>
				<FacebookButton onLogin={this.handleFbLogin}/>
				<hr />
				<FormGroup controlId="email" bsSize="large">
					<ControlLabel>Email</ControlLabel>
					<FormControl
						autoFocus
						type="email"
						value={this.state.email}
						onChange={this.handleChange}
					/>
				</FormGroup>
				<FormGroup controlId="password" bsSize="large">
					<ControlLabel>Password</ControlLabel>
					<FormControl
						value={this.state.password}
						onChange={this.handleChange}
						type="password"
					/>
				</FormGroup>
				<FormGroup controlId="confirmPassword" bsSize="large">
					<ControlLabel>Confirm Password</ControlLabel>
					<FormControl
						value={this.state.confirmPassword}
						onChange={this.handleChange}
						type="password"
					/>
				</FormGroup>
				<LoaderButton
					block
					bsSize="large"
					disabled={!this.validateForm()}
					type="submit"
					isLoading={this.state.isLoading}
					text="Signup"
					loadingText="Signing up…"
				/>
			</form>
		);
	}

	render() {
		return (
			<div className="Signup">
				{this.state.newUser === null
					? this.renderForm()
					: this.renderConfirmationForm()}
			</div>
		);
	}
}
