import React, { Component } from "react";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import "./Home.css";
import NoteListItem from "../components/NoteListItem"


export default class Home extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			notes: []
		};
	}

	async componentDidMount() {
		if (!this.props.isAuthenticated) {
			return;
		}

		try {
			const notes = await this.notes();
			this.setState({ notes });
		} catch (e) {
			alert(e);
		}

		this.setState({ isLoading: false });
	}

	notes() {
		return API.get("notes", "/notes");
	}


	renderNotesList(notes) {
		return [{}].concat(notes).map(
			(note, i) =>
				i !== 0
					? <NoteListItem note={note}></NoteListItem>
					: <LinkContainer
						key="new"
						to="/notes/new"
					>
						<ListGroupItem>
							<h4>
								<b>{"\uFF0B"}</b> Create a new note
							</h4>
						</ListGroupItem>
					</LinkContainer>
		);
	}


	renderLander() {
		return (
			<div className="lander">
				<h1>Scribble</h1>
				<p>A simple notes app built with React backed by a serverless architecture - API Gateway, AWS Lambdas, DynamoDB, S3, Cognito User & Identity Pools</p>
				<div>
					<Link to="/login" className="btn btn-info btn-lg">
						Login
					</Link>
					<Link to="/signup" className="btn btn-success btn-lg">
						Signup
					</Link>
				</div>
			</div>
		);
	}


	renderNotes() {
		return (
			<div className="notes">
				<PageHeader>Your Notes</PageHeader>
				<ListGroup>
					{!this.state.isLoading && this.renderNotesList(this.state.notes)}
				</ListGroup>
			</div>
		);
	}

	render() {
		return (
			<div className="Home">
				{this.props.isAuthenticated ? this.renderNotes() : this.renderLander()}
			</div>
		);
	}
}
