import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import "./App.css";
import Routes from "./Routes";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from "aws-amplify";
import config from "./config";



class App extends Component {
   constructor(props) {
      super(props);

      this.state = {
         isAuthenticated: false,
         isAuthenticating: true,
         federatedSignIn: false
      };
   }

   async componentDidMount() {
      this.loadFacebookSDK()

      try {
         await Auth.currentAuthenticatedUser();
         try {
            await Auth.currentSession();
            this.userHasAuthenticated(true);
         }
         catch (e) {
            this.userHasAuthenticated(true, true);
         }

      }
      catch(e) {
         if (e !== "not authenticated") {
            alert(e);
         }
      }

      this.setState({ isAuthenticating: false });
   }


   userHasAuthenticated = (authenticated, federatedSignIn) => {
      this.setState({ isAuthenticated: authenticated, federatedSignIn: federatedSignIn || false});
   }

   handleLogout = async event => {
      await Auth.signOut();

      this.userHasAuthenticated(false);
      this.props.history.push("/login");

   }


   loadFacebookSDK() {
      window.fbAsyncInit = function() {
         window.FB.init({
            appId            : config.social.FB,
            autoLogAppEvents : true,
            xfbml            : true,
            version          : 'v3.3'
         });
      };

      (function(d, s, id){
         var js, fjs = d.getElementsByTagName(s)[0];
         if (d.getElementById(id)) {return;}
         js = d.createElement(s); js.id = id;
         js.src = "https://connect.facebook.net/en_US/sdk.js";
         fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
   }


   render() {
      const childProps = {
         isAuthenticated: this.state.isAuthenticated,
         userHasAuthenticated: this.userHasAuthenticated
      };

      return (
         !this.state.isAuthenticating &&
         <div className="App container">
            <Navbar fluid collapseOnSelect>
               <Navbar.Header>
                  <Navbar.Brand>
                     <Link to="/">Scribble</Link>
                  </Navbar.Brand>
                  <Navbar.Toggle />
               </Navbar.Header>
               <Navbar.Collapse>
                  <Nav pullRight>
                     {this.state.isAuthenticated
                        ? <Fragment>
                           {!this.state.federatedSignIn && <LinkContainer to="/settings">
                              <NavItem>Settings</NavItem>
                           </LinkContainer>
                           }
                           <NavItem onClick={this.handleLogout}>Logout</NavItem>
                        </Fragment>
                        : <Fragment>
                           <LinkContainer to="/signup">
                              <NavItem>Signup</NavItem>
                           </LinkContainer>
                           <LinkContainer to="/login">
                              <NavItem>Login</NavItem>
                           </LinkContainer>
                        </Fragment>
                     }
                  </Nav>
               </Navbar.Collapse>
            </Navbar>
            <Routes childProps={childProps} />
         </div>
      );
   }




}

export default withRouter(App);

